<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 162 23" xml:space="preserve">
    <path d="M0,23h6.7V0H0V23z"></path>
    <path d="M9.9,23h6.7V10.9L25.1,23h5.7V0h-6.7v12.2L15.5,0H9.9V23z"></path>
    <path d="M42.1,23h7.1l8.8-23h-7l-5.3,15.2L40.2,0h-7L42.1,23z"></path>
    <path d="M60.4,23h17.3v-5.1H67v-3.8h9.3V8.9H67V5.1h10.6V0H60.4V23z"></path>
    <path d="M79.8,23h17v-5.1H86.5V0h-6.7V23z"></path>
    <path d="M125.1,23h6.7V5h6.4V0h-19.5v5h6.4V23z"></path>
    <path d="M147.1,23h6.7v-8.4L162,0h-7l-4.6,8.7L145.8,0h-7l8.2,14.6V23z"></path>
    <path class="highlight" d="M97.7,23h6.8l13.2-22.9h-6.8L97.7,23z"></path>
</svg>
  <HelloWorld/>
  <AdvertisementForm/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import AdvertisementForm from './components/AdvertisementForm'

export default {
  name: 'App',
  components: {
    AdvertisementForm,
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #d367c1;
  margin-top: 60px;
}

label{
  color: #d367c1;
}

.highlight{
  fill: #d367c1;
}
html{
  background-color: #27272c;
}
svg{
  max-width: 500px;
  fill: #fff;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #27272c;
  border-radius: 10px;
  /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);*/
}

label {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

textarea,
select,
input[type="number"],
input[type="text"] {
  /*width: 100%;*/
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #333;
  background: #222126;
  background-image: linear-gradient(8deg,#222126,#26262b);
}
textarea{
  width: 500px;
  height: 150px;
}

select{
  width: 500px;
  height: 60px;
}
input{
  width: 500px;
  height: 50px;
}
textarea:focus,
select:focus,
input[type="number"]:focus,
input[type="text"]:focus {
  outline: none;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.3);
}

button[type="submit"] {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #03adbc;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #039eab;
}

</style>