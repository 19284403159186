<template>
  <div>
    <form @submit.prevent="generateComment">
      <div>
        <label for="product-description">Product Description:</label>
        <textarea id="product-description" v-model="productDescription"></textarea>
      </div>
      <div>
        <label for="social-platform">Social Platform:</label>
        <select id="social-platform" v-model="socialPlatform">
          <option value="Facebook">Facebook</option>
          <option value="Instagram">Instagram</option>
          <option value="Pinterest">Pinterest</option>
          <option value="LinkedIn">LinkedIn</option>
        </select>
      </div>
      <div>
        <label for="target-audience">Target Audience:</label>
        <input type="text" id="target-audience" v-model="targetAudience">
      </div>
      <button type="submit">Generate Content</button>
    </form>
    <div v-if="comment">
      <h2>Generated Comment:</h2>
      <p>{{ comment }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      productDescription: '',
      socialPlatform: 'Facebook',
      maxCommentLength: 30,
      targetAudience: '',
      comment: ''
    }
  },
  methods: {
    async generateComment() {
      const apiKey = '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';
      try {
        const prompt =  `Act as social media creator and write 2 advertisement titles in a maximum of 30 characters on the basis of "${this.productDescription}" and "${this.socialPlatform}" and "${this.targetAudience}" which is not mandatory.`;

        const response = await axios.post('https://api.openai.com/v1/chat/completions', {
          // message: {role: 'advertiser', content: this.productDescription},
          messages: [
            {role: 'user', content: prompt},
            {role: 'assistant', content: prompt},
          ],
          model: 'gpt-3.5-turbo',
          temperature: 0.7,
          max_tokens: 100,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `
        Bearer ${apiKey}`
          }
        });

        this.comment = response.data.choices[0].message.content;
      } catch (error) {
        console.error(error);
      }
    }
  }
};

//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
</script>
